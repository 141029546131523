<script>
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
// import Skeleton from "@/view/front/components/skeleton";
import moment from "moment";
import Swal from "sweetalert2";
/**
 * page-jobs-new component
 */
export default {
  data() {
    return {
      auth: localStorage.getItem("id_token") !== null ? true : false,
      token: `Bearer ${localStorage.getItem("token")}`,
      user:
        localStorage.getItem("user") !== null
          ? JSON.parse(localStorage.getItem("user"))
          : {},
      profile:
        localStorage.getItem("profile") !== null
          ? JSON.parse(localStorage.getItem("profile"))
          : {},
      loading: true,
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      keywords: "",
      email: "",
      location: "",
      data: [],
      experiences: [],
      selected_job_experience: [],
      success: false,
    };
  },
  components: {
    Navbar,

    Switcher,
    // Skeleton,
    Footer,
  },
  mounted() {
    this.email = this.auth ? this.user.email : "";
    this.location = this.auth ? this.profile.city : "";
    this.getJobCareerLevel();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    getJobCareerLevel() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-job-career-levels`).then((response) => {
        this.experiences = response.data;
      });
    },
    submitJobAlert() {
      this.success = true;
      if (!this.keywords) {
        Swal.fire({
          title: "",
          text: "Please enter keyword!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      if (
        !this.selected_job_experience ||
        this.selected_job_experience == null ||
        this.selected_job_experience == ""
      ) {
        Swal.fire({
          title: "",
          text: "Please select job experience!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      if (!this.location) {
        Swal.fire({
          title: "",
          text: "Please select job location!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      axios
        .post(`${API_URL}/job-alert`, {
          keywords: this.keywords,
          location: this.location,
          career_level: this.selected_job_experience,
          email: this.email,
        })
        .then((res) => {
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 3000);
          if (res.data === "200") {
            Swal.fire({
              title: "",
              text: "Job alert has been saved.",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          } else {
            Swal.fire({
              title: "",
              text: "Some thing went rong",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          }
        });
    },
    _jobCareerSelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_experience.includes(event.target.value)) {
          var temp = this.selected_job_experience;
          temp.push(event.target.value);
          this.selected_job_experience = temp;
        }
      } else {
        var temp = [];
        this.selected_job_experience.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_experience = temp;
      }
    },
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="section_ch_p bg-light d-table w-100 mt-10">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-8 pagech1">
            <div class="blog_header pl-0">
              <div class="col-lg-12 p-0 w-100">
                <div class="blog_back">
                  <i class="fas fa-long-arrow-alt-left p-1"></i>
                  <router-link to="/career-resources"
                    >Back to blog list</router-link
                  >
                </div>
              </div>
              <h2 class="m_title">Interview Preparation</h2>
              <span class="m_title">by: job4u.pk (3 min read)</span>
            </div>

            <div class="p_about2">
              Congratulations on landing the job interview! You’re one step
              closer to gainful employment, and now the only thing left is to
              nail the interview. Of course, this can be a scary idea, but we’re
              here to help you prepare, so that you can wow your potential
              employers and be sure to get the job you want.
            </div>

            <div class="p_about2">
              Before the interview, be sure to research the company you are
              interviewing with. Read up on their story, core values, team, and
              work environment, and make note of any recent achievements,
              prizes, or milestones. These can act as conversation starters, or
              you can utilize them to highlight what you appreciate about the
              company and why you want to work there. This is also a good time
              to decide how to dress for the interview – the company pages and
              website can give you a good idea of what they expect, and you can
              tailor your outfit accordingly. It’s good to show personality and
              flair, but you also want to show the hiring personnel that you are
              a good fit, which is why a balance between personal style and what
              the company expects is a good option. Another critical thing to do
              before the interview is to know your resume and the post you’re
              applying for very well. Should your interviewer(s) ask for
              information regarding something on your CV, you need to know it
              well enough to not be caught fumbling and, when you know the role
              you’re interviewing for inside and out, you can ask insightful
              questions which make you seem informed and well-prepared.
            </div>

            <div class="p_about2">
              On the day of the interview, be sure to arrive early, by 5 or 10
              minutes. This gives you time to settle in, take in your
              surroundings, and calm yourself down before going in. This does
              mean adjusting for commute and other conditions accordingly, so be
              sure to keep that in mind when planning. Once you arrive, put your
              phone on silent, give your clothes a quick glance to make sure
              nothing is out of place, and get your CV and cover letter out and
              ready. This way, when you’re called in, you’re not fumbling to do
              a million things, and instead stay calm, confident, and collected.
            </div>

            <div class="p_about2">
              When you walk into the room where you’re being interviewed, your
              body language, posture, and even gait make an impression way
              before you say a word. This is why it is vital to stay calm and
              confident. Smile when entering, greet everyone present, and wait
              for them to ask you to take a seat. Try not to fidget or adjust
              your clothes, hair etc., and instead keep an air of
              professionalism and self-assurance. Keep some topics to open with
              in mind, because you and your interviewer(s) will open with small
              talk before going ahead to the questions, and it’s best to have
              some topics at the ready. Good ones should pertain to the company,
              building, or work environment (which is why we recommended taking
              in your surroundings), because this showcases your attention to
              detail and that you did your research, rather than walking in
              blind.
            </div>

            <div class="p_about2">
              Above all else, however, we recommend being yourself. Companies
              aren’t looking for a cookie cutter, fit to mold worker, and
              uniqueness is what makes interviewees stand out. If you’re putting
              on an act or pretending to have traits, skills, or experience that
              you don’t, you interviewers will very likely be able to tell, so
              be yourself, be confident, and you’ll ace that interview with no
              problem!
            </div>
            <br />
            <!-- <hr> -->
          </div>
          <div class="col-lg-4 col-md-4 mt-3">
            <div class="card">
              <div class="back_overlay">
                <div class="row">
                  <div class="col-lg-6 col-10">
                    <h2 class="">want a great job taillard to your skills ?</h2>
                    <p class="">
                      save time and find higher quality jobs by signing up to
                      job4u.pk
                    </p>
                    <button type="submit" class="btn btn-default join_btn mb-6">
                      <a href="/job-seeker-register">Join job4u.pk now! </a>
                    </button>
                  </div>
                  <div class="col-lg-6 col-2 cv_image_bg">
                    <!-- <img src="/images/carer_resorses/CV.png" alt=""> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="card mt-5">
              <div class="card-headerchch">Create a job alert!</div>
              <div class="card-body p-5">
                <form>
                  <div class="form-group">
                    <label class="labal_style" for="kyeword"
                      >keywords <span style="color: red">*</span></label
                    >
                    <input
                      type="text"
                      v-model="keywords"
                      class="form-control form-control-sm serach_i_ch"
                      id="kyeword"
                      placeholder="Enter Kyewords"
                    />
                  </div>

                  <div class="m_title pt-1 labal_style">
                    CAREER LEVELS <span style="color: red">*</span>
                  </div>
                  <div class="" style="">
                    <ul class="mt-2">
                      <li v-for="(item, index) in experiences" :key="index">
                        <input
                          type="checkbox"
                          :id="item.career_level"
                          :name="item.career_level"
                          class="custom-control-input"
                          :value="item.career_level"
                          v-on:click="_jobCareerSelection($event)"
                        /><label
                          class="custom-control-label m_title"
                          :for="item.career_level"
                          style="font-size: 13px"
                          >{{ item.career_level }}</label
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="form-group">
                    <label class="labal_style" for="exampleInputlocalisation1"
                      >Job Location</label
                    >
                    <input
                      type="text"
                      v-model="location"
                      class="form-control form-control-sm serach_i_ch"
                      id="exampleInputlocalisation1"
                      placeholder="localisation"
                    />
                  </div>
                  <div class="form-group">
                    <label class="labal_style" for="exampleInputYouremail1"
                      >Your email<span style="color: red">*</span></label
                    >
                    <input
                      type="text"
                      v-model="email"
                      class="form-control form-control-sm serach_i_ch"
                      id="exampleInputYouremail1"
                      placeholder="Youremail"
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      class="card-headerch_btn bg_blue_ch"
                      @click="submitJobAlert()"
                      :class="
                        success ? 'spinner spinner-light pinner-right' : ''
                      "
                    >
                      Create job alert!
                    </button>
                    <!-- <div
                      v-if="success"
                      class="alert ml-5"
                      style="
                        background-color: #d4edda;
                        border-color: #d4edda;
                        color: #155724;
                      "
                      role="alert"
                    >
                      Form Submited
                    </div> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
